const initialState = {
  loading: false,
  account: null,
  smartContract: null,
  web3: null,
  totalSupply: 0,
  mintingState: 'NONE', // NONE, MINTING, MINTED, FAILED
  errorMsg: "",
};

const blockchainReducer = (state = initialState, action) => {
  switch (action.type) {
    case "CONNECTION_REQUEST":
      return {
        ...initialState,
        loading: true,
      };
    case "CONNECTION_SUCCESS":
      return {
        ...state,
        loading: false,
        account: action.payload.account,
        web3: action.payload.web3,
        smartContract: action.payload.smartContract,
        totalSupply: action.payload.totalSupply,
      };
    case "CONNECTION_FAILED":
      return {
        ...initialState,
        loading: false,
        errorMsg: action.payload,
      };
    case "MINT_REQUEST":
      return {
        ...state,
        loading: true,
        error: false,
        mintingState: 'MINTING',
      };
    case "MINT_SUCCESS":
      return {
        ...state,
        loading: false,
        error: false,
        mintingState: 'MINTED',
      };
    case "MINT_FAILED":
      return {
        ...state,
        loading: false,
        error: true,
        mintingState: 'FAILED',
        errorMsg: action.payload,
      };
    case "UPDATE_ACCOUNT":
      return {
        ...state,
        account: action.payload.account,
      };
    case "CLEAR_ERRORS":
      return {
        ...state,
        errorMsg: '',
      };
    default:
      return state;
  }
};

export default blockchainReducer;
