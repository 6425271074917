import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { forwardRef } from 'react';

function ToastAlert({ open, handleClose, message }) {

  const position = {
    vertical: 'bottom',
    horizontal: 'right'
  };

  const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} {...props} />;
  });

  return (
    <div>
      <Snackbar
        open={open}
        anchorOrigin={position}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          { message }
        </Alert>
      </Snackbar>
    </div>
  );
}

export default ToastAlert;
