import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { ENTS_LOGO } from "../../constants/constants";
import * as s from '../../styles/globalStyles';
import { openInNewTab } from "../../utils/utils";

const HeaderContainer = styled(s.Container)`
  position: fixed;
  top: 0;
  left: 0;
  min-height: ${s.HeaderHeight};
  height: ${s.HeaderHeight};
  background-color: var(--nav-bar);
  padding: 0 40px;
  z-index: 1000;
`;

const HeaderOption = styled(s.NavTitle)`
  cursor: pointer;
`;

const EntsLogo = styled.img`
  width: 200px;
  cursor: pointer;
`;

function Header({ growthMapRef, teamRef }) {
    const [position, setPosition] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);
    const dispatch = useDispatch();

    const scrollToGrowthMap = (e) => {
      e.preventDefault();
      growthMapRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const scrollToTeam = (e) => {
      e.preventDefault();
      teamRef.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(()=> {
      const handleScroll = () => {
         let moving = window.pageYOffset;
         
         setVisible(position > moving);
         setPosition(moving);
      };
      window.addEventListener("scroll", handleScroll);
      return(() => {
         window.removeEventListener("scroll", handleScroll);
      });
    });

    const headerStyle = {
      top: visible ? 0 : `-${s.HeaderHeight}`,
      transition: 'top 0.4s ease-out'
    };

    const entsUrl = process.env.REACT_APP_ENTS_URL;

    return (
        <HeaderContainer style={headerStyle} fd={'row'} jc={'space-between'} ai={'center'}>
          <s.Container fg={4} fd={'row'} gap={'30px'}>
            <HeaderOption onClick={scrollToGrowthMap}>GrowthMap</HeaderOption>
            <HeaderOption onClick={scrollToTeam}>Team</HeaderOption>
            {/* <HeaderOption>Gallery</HeaderOption>
            <HeaderOption>Members</HeaderOption> */}
          </s.Container>
          <s.Container fg={2} fd={'row'} jc={'flex-end'} ai={'center'}>
            <s.NavTitle>By</s.NavTitle>
            <EntsLogo src={ENTS_LOGO} onClick={() => openInNewTab(entsUrl) } />
          </s.Container>
        </HeaderContainer>
    );
}

export default Header;
