import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { ENTS_LOGO } from "../../constants/constants";
import * as s from '../../styles/globalStyles';

const HeaderContainer = styled(s.Container)`
  position: absolute;
  top: 0;
  left: 0;
  min-height: ${s.HeaderHeight};
  height: ${s.HeaderHeight};
  background-color: var(--nav-bar);
  z-index: 1000;
`;

const EntsLogo = styled.img`
  width: 200px;
`;

function MobileHeader() {
    const [position, setPosition] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(true);

    useEffect(()=> {
      const handleScroll = () => {
         let moving = window.pageYOffset;
         
         setVisible(position > moving);
         setPosition(moving);
      };
      window.addEventListener("scroll", handleScroll);
      return(() => {
         window.removeEventListener("scroll", handleScroll);
      });
    });

    return (
        <HeaderContainer fd={'row'} jc={'center'} ai={'center'}>
          <s.Container fd={'row'} jc={'center'} ai={'center'}>
            <EntsLogo src={ENTS_LOGO} />
          </s.Container>
        </HeaderContainer>
    );
}

export default MobileHeader;
