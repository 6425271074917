export const OPENSEA_TESTNET_URL = "https://testnets.opensea.io";
export const OPENSEA_URL = "https://opensea.io";
export const ENTS_LOGO = '/img/logo.png';
export const HOME_BACKGROUND = '/img/background.jpg';
export const FOOTER_BACKGROUND = '/img/footer-background.jpg';
export const HOME_LOGO = '/img/entrees-logo.png';
export const ENTREES_PATH = 'img/entrees/';
export const CONNECT_BUTTON = '/img/connect-button.png';
export const CONNECT_BUTTON_PRESSED = '/img/connect-button-pressed.png';
export const MINT_BUTTON = '/img/mint-button.png';
export const MINT_BUTTON_PRESSED = '/img/mint-button-pressed.png';
export const GOLDEN_COIN_GIF = '/img/coin.gif';
export const ICN_PIXEL_ARROW_UP = '/img/icons/pixel-arrow-up.svg';
export const ICN_PIXEL_ARROW_DOWN = '/img/icons/pixel-arrow-down.svg';
export const ICN_PIXEL_SMILE = '/img/icons/pixel-smile.svg';
export const OPENSEA_FULL_LOGO_LIGHT = '/img/opensea-full-logo-light.svg';
export const OPENSEA_FULL_LOGO_DARK = '/img/opensea-full-logo-dark.svg';
export const FROG = '/img/frog.png';
export const TRUNK = '/img/trunk.png';
export const TREE = '/img/tree.png';
export const AME = '/img/team/ame.png';
export const ANTO = '/img/team/anto.png';
export const BENITO = '/img/team/benito.png';
export const CATA = '/img/team/cata.png';
export const CRIS = '/img/team/cris.png';
export const DIEGO = '/img/team/diego.png';
export const IGNACIO = '/img/team/ignacio.png';
export const JORGE = '/img/team/jorge.png';
export const NICO = '/img/team/nico.png';
export const OSCAR = '/img/team/oscar.png';
export const WILLY = '/img/team/willy.png';
export const DISCORD = '/img/icons/discord.png';
export const INSTAGRAM = '/img/icons/instagram.png';
export const POLYSCAN = '/img/icons/polygon.png';
