import { useEffect, useState } from 'react';
import {
    ENTREES_PATH, HOME_LOGO,
    MINT_BUTTON, MINT_BUTTON_PRESSED,
    CONNECT_BUTTON, CONNECT_BUTTON_PRESSED
} from '../constants/constants';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Modal } from '@mui/material';

import styled, { keyframes } from 'styled-components';
import * as s from '../styles/globalStyles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { connect, mint } from '../redux/blockchain/blockchainActions';
import GoldenCoin from '../components/GoldenCoin';
import MintedModal from '../components/MintedModal';
import { useIsMobile } from '../hooks/windowSize';

const typing = keyframes`
    from { width: 0 }
    to { width: 100% }
`;

const blinkCaret = keyframes`
    from, to { border-color: orange }
    50% { border-color: transparent }
`;


const TypingLabel = styled(s.TextSubTitle)`
    overflow: hidden;
    border-right: .15em solid transparent;
    white-space: nowrap;
    margin: 0 auto;
    letter-spacing: .15em;
    animation: 
        ${typing} 3.5s steps(30, end),
        ${blinkCaret} .5s step-end infinite;
`;

const HomeLogo = styled.img`
    width: 70%;
`;

const scroll = keyframes`
    0% {
        transform: transform: translateX(0);
    }

    100% {
        transform: translateX(calc(-150px * 9));
    }
`;

const SliderContainer = styled.div`
    width: 100%;
    min-height: 220px;
    background-color: rgba(0,0,0,0.6);
    overflow: hidden;
    position: relative;
`;

const Slider = styled(s.Container)`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

const MintButtonContainer = styled(Slider)`
    z-index: 10;
    align-items: center;
    margin-top: 25px;
`;

const MintButton = styled.div`
    height: 120px;
    width: 250px;
    background-image: url(${MINT_BUTTON});
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    &:active{
        background-image: url(${MINT_BUTTON_PRESSED});
    }
`;

const ConnectButton = styled(MintButton)`
    background-image: url(${CONNECT_BUTTON});
    filter: grayscale(0);
    &:active{
        background-image: url(${CONNECT_BUTTON_PRESSED});
    }
`;

const SlideTrack = styled.div`
    animation: ${scroll} 40s linear infinite;
    display: flex;
    width: calc(150px * 18);
`;

const EntreeContainer = styled.div`
    height: 150px;
    width: 150px;
    margin: 10px;
    opacity: 0.5;
`;

const EntreeImg = styled.img`
    height: 150px;
    width: 150px;
`;

const SliderLabel = styled(s.TextSubTitle)`
    text-shadow: 3px 3px 5px #000, -2px 1px 5px #000;
`;

const AmountText = styled.div`
    width: 80px;
    font-size: 36px;
    text-align: center;
`;

const ModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const AmountSelector = styled(s.Container)`
    align-items: center;
    background-color: var(--light-opacity);
    width: auto;
`;


const IcnStyle = {
    fontSize: '48px',
    cursor: 'pointer',
    color: 'var(--accent)',
};

function Home({ gameRef }) {
    const dispatch = useDispatch();
    const [mintAmount, setMintAmount] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const blockchain = useSelector((state) => state.blockchain);

    const isMobile = useIsMobile();

    const isMinting = (mintingState) => mintingState === 'MINTING';
    const wasMinted = (mintingState) => mintingState === 'MINTED';
    const mintFailed = (mintingState) => mintingState === 'FAILED';

    const scrollToGame = () => {
        gameRef.current.scrollIntoView({ behavior: "smooth" });
    };

    const incrementAmount = () => {
        if (mintAmount >= 20) return;
        setMintAmount(mintAmount + 1);
    }

    const decrementAmount = () => {
        if (mintAmount <= 1) return;
        setMintAmount(mintAmount - 1);
    }

    const handleClose = () => {
        if (isMinting(blockchain.mintingState)) return;
        setOpenModal(false);
    };

    useEffect(() => {
        if (isMinting(blockchain.mintingState)) {
            setOpenModal(true);
        } else if (mintFailed(blockchain.mintingState)) {
            setOpenModal(false);
        }
    }, [blockchain.mintingState]);

    const entrees = () => {
        const entreesList = [];
        for (let i = 1; i < 19; i++) {
            entreesList.push(i);
        }
        return entreesList.map((i) => {
            return (
                <EntreeContainer key={i}>
                    <EntreeImg src={`${ENTREES_PATH}${i}.png`} />
                </EntreeContainer>
            );
        });
    };

    return (
        <s.FullScreenDiv style={{ marginTop: s.HeaderHeight }}>
            <Modal
                open={openModal}
                onClose={handleClose}
                disableAutoFocus={true}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <ModalContainer>
                    {
                        isMinting(blockchain.mintingState)
                            ? <GoldenCoin />
                            : wasMinted(blockchain.mintingState) ? <MintedModal /> : <></>
                    }
                </ModalContainer>
            </Modal>
            <s.Container fg={1} jc={isMobile ? 'space-evenly' : 'space-between'} ai={'center'} style={{ height: '100%' }}>
                <HomeLogo src={HOME_LOGO} style={{ marginTop: 50 }} />
                <AmountSelector>
                    {// blockchain.totalSupply >= 100 ?                   
                    // (<><s.Container fd={'row'} ai={'center'} jc={'center'}>
                    //     <RemoveIcon style={IcnStyle} onClick={decrementAmount} />
                    //     <AmountText>
                    //         <s.TextDescription style={{fontSize: '24px'}}>{ mintAmount }</s.TextDescription>
                    //     </AmountText>
                    //     <AddIcon style={IcnStyle} onClick={incrementAmount} />
                    // </s.Container>
                    // <s.TextSubTitle style={{marginTop: '5px'}}>MINT AMOUNT</s.TextSubTitle>
                    // </>) :
                    <>
                        <s.TextSubTitle style={{ marginTop: '5px', fontSize: 24, letterSpacing: '.15em' }}>CONNECT & MINT</s.TextSubTitle>
                        <TypingLabel style={{ marginTop: '5px', fontSize: isMobile ? 16 : 22, letterSpacing: '.15em' }}>YOUR CRYPTOKEULE</TypingLabel>
                    </>
                    }
                </AmountSelector>
                <SliderContainer>
                    <Slider ai={'center'}>
                        <SlideTrack>
                            {entrees()}
                            {entrees()}
                        </SlideTrack>
                        <SliderLabel>
                            -PRESERVE BIODIVERSITY AND MAKE AN INVESTMENT OUT OF IT-
                        </SliderLabel>
                    </Slider>
                    <MintButtonContainer>
                        {
                            !blockchain.account
                                ? <ConnectButton onClick={() => dispatch(connect())} />
                                : <MintButton onClick={
                                        () => /*blockchain.totalSupply < 100 ? scrollToGame() :*/ dispatch(mint())
                                    }
                                />
                        }
                    </MintButtonContainer>
                </SliderContainer>
            </s.Container>
        </s.FullScreenDiv>
    );
}

export default Home;