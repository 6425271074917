import Web3EthContract from "web3-eth-contract";
import Web3 from "web3";
import store from "../store";
import { ERROR_MESSAGES, UNRECOGNIZED_CHAIN_ERROR } from "../../constants/errors";
import { calculatePrice, getProvenance } from "../../utils/utils";

const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

const connectSuccess = (payload) => {
  return {
    type: "CONNECTION_SUCCESS",
    payload,
  };
};

const connectFailed = (payload) => {
  return {
    type: "CONNECTION_FAILED",
    payload,
  };
};

const mintRequest = () => {
  return {
    type: "MINT_REQUEST",
  };
};

const mintSuccess = () => {
  return {
    type: "MINT_SUCCESS",
  };
};

const mintFailed = (payload) => {
  return {
    type: "MINT_FAILED",
    payload,
  };
};

const updateAccountRequest = (payload) => {
  return {
    type: "UPDATE_ACCOUNT",
    payload: payload,
  };
};

const clearErrorsRequest = () => {
  return {
    type: "CLEAR_ERRORS",
  };
};

const polygonSwitchNetwork = [{
  chainId: '0x89'
}]

const mumbaiSwitchNetwork = [{
  chainId: '0x13881'
}]

const polygonParams = [{
  chainId: '0x89',
  chainName: 'Polygon Mainnet',
  nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
  },
  rpcUrls: ['https://polygon-rpc.com/'],
  blockExplorerUrls: ['https://polygonscan.com/']
}];

const mumbaiParams = [{
  chainId: '0x13881',
  chainName: 'Mumbai Testnet',
  nativeCurrency: {
      name: 'MATIC',
      symbol: 'MATIC',
      decimals: 18
  },
  rpcUrls: ['https://matic-mumbai.chainstacklabs.com'],
  blockExplorerUrls: ['https://mumbai.polygonscan.com/']
}];

export const connect = () => {
  return async (dispatch) => {
    dispatch(connectRequest());
    const abiResponse = await fetch("/config/abi-dev.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const abi = await abiResponse.json();
    const ENV = process.env;

    const { ethereum } = window;
    const metamaskIsInstalled = ethereum && ethereum.isMetaMask;
    if (!metamaskIsInstalled) {
      dispatch(connectFailed(ERROR_MESSAGES.METAMASK_NOT_INSTALLED));
      return;
    }

    Web3EthContract.setProvider(ethereum);
    let web3 = new Web3(ethereum);
    try {
      const hexChainId = await ethereum.request({ method: 'eth_chainId' });
      const chainId = String(parseInt(hexChainId, 16));
      if (chainId !== ENV.REACT_APP_CHAIN_ID) {
        try {
          await ethereum.request({ method: 'wallet_switchEthereumChain', params: JSON.parse(ENV.REACT_APP_IS_MAINNET) ? polygonSwitchNetwork : mumbaiSwitchNetwork });
        } catch (error) {
          if (error.code === UNRECOGNIZED_CHAIN_ERROR) {
            try {
              await ethereum.request({ method: 'wallet_addEthereumChain', params: JSON.parse(ENV.REACT_APP_IS_MAINNET) ? polygonParams : mumbaiParams });
              await ethereum.request({ method: 'wallet_switchEthereumChain', params: JSON.parse(ENV.REACT_APP_IS_MAINNET) ? polygonSwitchNetwork : mumbaiSwitchNetwork });
            } catch (err) {
              dispatch(connectFailed(ERROR_MESSAGES.WRONG_CHAIN.replace('$i', ENV.REACT_APP_CHAIN_NAME)));
            } 
          } else {
            dispatch(connectFailed(ERROR_MESSAGES.WRONG_CHAIN.replace('$i', ENV.REACT_APP_CHAIN_NAME)));
            return;
          }
        }
      }

      const accounts = await ethereum.request({ method: "eth_requestAccounts" });
      const SmartContractObj = new Web3EthContract(
        abi,
        ENV.REACT_APP_CONTRACT_ADDRESS
      );
      const totalSupply = await SmartContractObj.methods.totalSupply().call();
      console.log(totalSupply);
      dispatch(
        connectSuccess({
          account: accounts[0],
          smartContract: SmartContractObj,
          web3,
          totalSupply
        })
      );

      // Add listeners start
      ethereum.on("accountsChanged", (accounts) => {
        dispatch(updateAccount(accounts[0]));
      });
      ethereum.on("chainChanged", async () => {
        const hexChainId = await ethereum.request({ method: 'eth_chainId' });
        const chainId = String(parseInt(hexChainId, 16));
        if (chainId !== ENV.REACT_APP_CHAIN_ID) {
          window.location.reload();
        }
      });
    } catch (err) {
      console.log(err);
      dispatch(connectFailed(ERROR_MESSAGES.GENERIC_ERROR));
    }
  };
};

export const mint = () => {
  return async (dispatch) => {
    dispatch(mintRequest());
    const blockchain = await store.getState().blockchain;
    const totalSupply = blockchain.totalSupply;
    const cost = calculatePrice(totalSupply);
    const gasPrice = process.env.REACT_APP_GAS_PRICE;
    const gasLimit = process.env.REACT_APP_GAS_LIMIT;

    const tx = {
      gasPrice: String(gasPrice),
      gas: String(gasLimit),
      to: process.env.REACT_APP_CONTRACT_ADDRESS,
      from: blockchain.account,
      value: cost
    };

    const provenance = await getProvenance();
    if (!provenance) {
      dispatch(mintFailed());
    }

    blockchain.smartContract.methods
      .discover(provenance, 'matic')
      .send(tx)
      .once("error", (err) => {
        dispatch(mintFailed());
      })
      .then((receipt) => {
        dispatch(mintSuccess());
      });
  }
};

export const updateAccount = (account) => {
  return async (dispatch) => {
    dispatch(updateAccountRequest({ account: account }));
  };
};

export const clearErrors = () => {
  return async (dispatch) => {
    dispatch(clearErrorsRequest());
  };
};
