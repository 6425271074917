import { Modal, Fade, Backdrop } from '@mui/material';
import { useEffect, useRef, useState, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import GreenListModal from '../components/GreenListModal';
import * as s from '../styles/globalStyles';

const ModalContainer = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;

const Game = forwardRef((props, ref) => {
    const iframeRef = useRef(null);
    const [openModal, setOpenModal] = useState(false);
    const [finalScore, setFinalScore] = useState(0);
    const [gameOver, setGameOver] = useState(false);
    const [loadingGame, setLoadingGame] = useState(true);
    const [gameReady, setGameReady] = useState(false);
    const blockchain = useSelector((state) => state.blockchain);
    const gameUrl = process.env.REACT_APP_GAME_URL

    const receiveScore = (e) => {
        if (e.origin !== gameUrl) return;
        const data = e.data;
        if (data.hasOwnProperty('loaded')) {
            setGameReady(true);
        }
        setGameOver(true);
        setFinalScore(data.finalScore);
    }

    const handleIframe = () => {
        setLoadingGame(false);
    }

    useEffect(() => {
        window.addEventListener("message", receiveScore, false);
    }, []);

    useEffect(() => {
        if (gameOver && finalScore >= 3) {
            setOpenModal(true);
        }
    }, [gameOver, finalScore]);

    useEffect(() => {
        if (!loadingGame && gameReady && blockchain.account) {
            iframeRef.current.contentWindow.postMessage({ insertedCoin: true }, '*');
        }
    }, [blockchain.account, loadingGame, gameReady]);

    const handleClose = () => {
        setOpenModal(false);
    };
    
    return (
        <s.FullScreenDiv style={{height: '100vh', display: 'flex'}} ref={ref}>
            <Modal
                open={openModal}
                onClose={handleClose}
                disableAutoFocus={true}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Fade in={openModal}>
                    <ModalContainer>
                        <GreenListModal />
                    </ModalContainer>
                </Fade>
            </Modal>
            <s.Container ai='center' jc='center' style={{ margin: '0 auto' }}>
                <iframe
                    ref={iframeRef}
                    style={{ width: 480, height: 720}}
                    src={gameUrl}
                    onLoad={handleIframe}
                />
            </s.Container>
        </s.FullScreenDiv>
    );
});

export default Game;