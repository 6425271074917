import styled, { keyframes } from 'styled-components';
import * as s from '../styles/globalStyles';
import { GOLDEN_COIN_GIF } from '../constants/constants';

const GoldenCoinGif = styled.img`
    width: 145px;
    height: auto;
`;

const CoinHead = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
`;

const CoinTail = styled(CoinHead)`
    transform: rotateY(180deg);
`;

const spin = keyframes`
    0%{
        transform: rotateY(0);
    }

    100%{
        transform: rotateY(1980deg);
    }
`;

const CoinContainer = styled.div`
    height: 150px;
    width: 150px;
    position: relative;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    animation: ${spin} 15s linear infinite;
`;

function GoldenCoin() {
    return (
        <>
            <CoinContainer>
                <CoinHead><GoldenCoinGif src={GOLDEN_COIN_GIF} /></CoinHead>
                <CoinTail><GoldenCoinGif src={GOLDEN_COIN_GIF} /></CoinTail>
            </CoinContainer>
            <s.TextSubTitle>MINTING...</s.TextSubTitle>
        </>
    );
}

export default GoldenCoin;