import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { ICN_PIXEL_SMILE } from '../constants/constants';
import * as s from '../styles/globalStyles';

const GreenListedContainer = styled(s.Container)`
    justify-content: center;
    align-items: center;
    background-color:  var(--dark-green);
    border: 5px solid var(--black);
    padding: 15px;
`;

const GreenListedBtn = styled.button`
    width: 150px;
    font-family: Pixeled, monospace;
    background-color: white;
    border: 2px solid var(--black);
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
`;

function GreenListModal() {
    const blockchain = useSelector((state) => state.blockchain);

    const openInNewTab = url => {
        window.open(url, '_blank', 'noopener,noreferrer');
    };

    return (
        <GreenListedContainer>
            <s.TextSubTitle>GET GREENLISTED</s.TextSubTitle>
            <img style={{width: '80px'}} src={ICN_PIXEL_SMILE} />
            <GreenListedBtn onClick={() => openInNewTab('https://forms.gle/6wi8niutNsV6rhri7')}>Get GreenListed</GreenListedBtn>
        </GreenListedContainer>
    );
}

export default GreenListModal;