import { useEffect, useState } from "react";

const getIsMobile = () => window.innerWidth <= 480;
const getIsTablet = () => window.innerWidth <= 768;
const getIsLaptop = () => window.innerWidth <= 1024;

export function useIsMobile() {
    const [isMobile, setIsMobile] = useState(getIsMobile());

    useEffect(() => {
        const onResize = () => {
            setIsMobile(getIsMobile());
        }

        window.addEventListener("resize", onResize);
    
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    
    return isMobile;
}

export function useIsTablet() {
    const [isTablet, setIsTablet] = useState(getIsTablet());

    useEffect(() => {
        const onResize = () => {
            setIsTablet(getIsTablet());
        }

        window.addEventListener("resize", onResize);
    
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    
    return isTablet;
}

export function useIsLaptop() {
    const [isLaptop, setIsLaptop] = useState(getIsLaptop());

    useEffect(() => {
        const onResize = () => {
            setIsLaptop(getIsLaptop());
        }

        window.addEventListener("resize", onResize);
    
        return () => {
            window.removeEventListener("resize", onResize);
        }
    }, []);
    
    return isLaptop;
}