import axios from 'axios';
import { OPENSEA_TESTNET_URL, OPENSEA_URL } from '../constants/constants';

export const getTokenOpenseaUrl = (tokenId) => {
    const openseaUrl = Boolean(process.env.REACT_APP_IS_MAINNET) ? OPENSEA_URL : OPENSEA_TESTNET_URL;
    const contractUrl = process.env.REACT_APP_CONTRACT_ADDRESS;
    const chainName = process.env.REACT_APP_CHAIN_NAME;
    return `${openseaUrl}/assets/${chainName}/${contractUrl}/${tokenId}`;
}

export const truncate = (input, len) => input.length > len ? `${input.substring(0, len)}...` : input;

export const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

export const calculatePrice = (totalSupplyAct) => {
    if (totalSupplyAct >= 100 && totalSupplyAct <= 5000) return 33000000000000000000;
    else if (totalSupplyAct >= 5001 && totalSupplyAct <= 8000 ) return 40000000000000000000;
    else if (totalSupplyAct >= 8001 && totalSupplyAct <= 10000 ) return 50000000000000000000;
    return 33000000000000000000;
}

export const getProvenance = async () => {
    const backendUrl = process.env.REACT_APP_ENTREES_BACKEND;
    try {
        const response = await axios.get(`${backendUrl}/next`);
        return response.data.provenance;
    } catch (err) {
        return false;
    }
}