import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import Header from "./components/layout/Header";
import Footer from "./components/layout/Footer";
import MobileHeader from "./components/layout/MobileHeader";
import ToastAlert from "./components/ToastAlert";
import { FOOTER_BACKGROUND, HOME_BACKGROUND } from "./constants/constants";
import { useIsMobile } from "./hooks/windowSize";
import Game from "./pages/Game";
import GrowthMap from "./pages/GrowthMap";
import Home from "./pages/Home";
import Team from "./pages/Team";
import { clearErrors } from "./redux/blockchain/blockchainActions";
import * as s from "./styles/globalStyles";

function App() {
  const [displayToast, setDisplayToast] = useState(false);
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const isMobile = useIsMobile();

  const growthMapRef = useRef(null);
  const teamRef = useRef(null);
  const gameRef = useRef(null);

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setDisplayToast(false);
    dispatch(clearErrors());
  };

  useEffect(() => {
    if (blockchain.errorMsg) {
      setDisplayToast(true);
    }
  }, [blockchain.errorMsg]);

  return (
    <s.Screen>
      <Router>
        { isMobile ? <MobileHeader /> : <Header growthMapRef={growthMapRef} teamRef={teamRef} /> }
        <div style={{ display: 'flex', position: 'relative', flexDirection: 'column' }}>
          <s.ScreenSection image={HOME_BACKGROUND}>
            <Home gameRef={gameRef} />
            { /* <GrowthMap ref={growthMapRef} /> */ }
          </s.ScreenSection>
          <s.ScreenSection image={FOOTER_BACKGROUND}>
            <Team ref={teamRef} />
            <Game ref={gameRef} />
            <Footer />
          </s.ScreenSection>
        </div>
        <ToastAlert open={displayToast} handleClose={handleCloseToast} message={blockchain.errorMsg} />
      </Router>
    </s.Screen>
  );
}

export default App;
