import styled from 'styled-components';
import { ICN_PIXEL_SMILE, OPENSEA_FULL_LOGO_DARK } from '../constants/constants';
import * as s from '../styles/globalStyles';
import { openInNewTab } from '../utils/utils';

const MintedModalContainer = styled(s.Container)`
    justify-content: center;
    align-items: center;
    background-color:  var(--dark-green);
    border: 5px solid var(--black);
    padding: 15px;
`;

const OpenseaLogo = styled.img`
    width: 150px;
    background-color: white;
    border: 2px solid var(--black);
    border-radius: 15px;
    padding: 10px;
    cursor: pointer;
`;

function MintedModal() {
    const openseaUrl = process.env.REACT_APP_OPENSEA_LINK;

    return (
        <MintedModalContainer>
            <s.TextSubTitle>ENTREE SUCCESSFULLY MINTED</s.TextSubTitle>
            <img style={{width: '80px'}} src={ICN_PIXEL_SMILE} />
            <s.TextSubTitle style={{marginTop: '-20px', marginBottom: '20px'}}>Check it out on opensea</s.TextSubTitle>
            <OpenseaLogo src={OPENSEA_FULL_LOGO_DARK} onClick={() => openInNewTab(openseaUrl)} />
        </MintedModalContainer>
    );
}

export default MintedModal;