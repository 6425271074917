import styled from "styled-components";
import { DISCORD, INSTAGRAM, POLYSCAN } from "../../constants/constants";

import * as s from '../../styles/globalStyles';
import { openInNewTab } from "../../utils/utils";

const HeaderContainer = styled(s.Container)`
  position: absolute;
  min-height: ${s.HeaderHeight};
  height: ${s.HeaderHeight};
  background-color: var(--accent-text);
  padding: 0 40px;
  z-index: 1000;
`;

const Icon = styled.img`
    cursor: pointer;
    width: 40px;
`;

function Footer() {

    return (
        <HeaderContainer fd={'column'} jc={'center'} ai={'center'}>
          <s.TextDescription style={{marginBottom: 10 }}>Reach to us</s.TextDescription>
          <s.Container jc={'center'} ai={'center'} fd={'row'} gap={'30px'}>
            <Icon src={DISCORD} onClick={() => openInNewTab('https://discord.gg/9gk53BnhAP')}/>
            <Icon src={POLYSCAN} onClick={() => openInNewTab('https://polygonscan.com/address/0x32ec3fEeAEa921be5F4a7e1d6Dc153897922711F')}/>
            <Icon src={INSTAGRAM} onClick={() => openInNewTab('https://www.instagram.com/entreesnft/')}/>
          </s.Container>
        </HeaderContainer>
    );
}

export default Footer;
