import { forwardRef } from 'react';
import styled from 'styled-components';
import { CRIS, DIEGO, IGNACIO, NICO, OSCAR, WILLY, AME, CATA, BENITO, JORGE, ANTO } from '../constants/constants';
import { useIsMobile } from '../hooks/windowSize';
import * as s from '../styles/globalStyles';

const MainMembersRow = styled(s.Container)`
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5px;
    width: 100%;
`;

const MembersRow = styled(s.Container)`
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 5px;
    flex-wrap: wrap;
    width: 100%;
`;

const MainMember = styled.img`
    width: 60%;
`;

const Member = styled.img`
    width: 70%;
`;

const MemberContainer = styled(s.Container)`
    flex-grow: 1;
    min-width: ${({ isMobile }) => (isMobile ? "50%" : "25%")};
    align-items: center;
    cursor: pointer;
`;

const teamContainerStyle = {
    width: '70%',
    padding: 30,
    paddingBottom: 60,
    backgroundColor: 'var(--medium-bright)',
};

const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
};

const Team = forwardRef((props, ref) => {
    const isMobile = useIsMobile();

    return (
        <s.FullScreenDiv style={{height: 'auto', marginBottom: 50}} ref={ref}>
            <s.Container ai='center' style={{ margin: '30px 0' }}>
                <s.MainTitle>Team</s.MainTitle>
            </s.Container>
            <s.Container ai='center'>
                <s.Container style={teamContainerStyle}>
                    <MainMembersRow>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/ignaciopugasalman/')}>
                            <MainMember src={IGNACIO} />
                            <s.TextDescription>Ignacio</s.TextDescription>
                            <s.TextDescription>Project Director</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/nicolas-laban-20592415a/')}>
                            <MainMember src={NICO} style={{ transform: 'translateX(15px)' }}/>
                            <s.TextDescription>Nicolas</s.TextDescription>
                            <s.TextDescription>Conservation Expert</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/oscar-castillo-arce-033a03170/')}>
                            <MainMember src={OSCAR} />
                            <s.TextDescription>Oscar</s.TextDescription>
                            <s.TextDescription>Smart Contract Dev</s.TextDescription>
                        </MemberContainer>
                    </MainMembersRow>
                    <MembersRow>
                        <MemberContainer isMobile={isMobile}>
                            <Member src={AME} />
                            <s.TextDescription>Amelia</s.TextDescription>
                            <s.TextDescription>Designer</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile}>
                            <Member src={DIEGO} />
                            <s.TextDescription>Diegong</s.TextDescription>
                            <s.TextDescription>Artist</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/cristobal-silva-b35007131/')}>
                            <Member src={CRIS} />
                            <s.TextDescription>Cristobal</s.TextDescription>
                            <s.TextDescription>Fullstack Dev</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/willyog/')}>
                            <Member src={WILLY} />
                            <s.TextDescription>Willy</s.TextDescription>
                            <s.TextDescription>Mentor</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile}>
                            <Member src={ANTO} />
                            <s.TextDescription>Martina</s.TextDescription>
                            <s.TextDescription>RRSS</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/benito-gonz%C3%A1lez-honorato-30542a82/')}>
                            <Member src={BENITO} />
                            <s.TextDescription>Benito</s.TextDescription>
                            <s.TextDescription>Backup Dev</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/cokeghm/')}>
                            <Member src={JORGE} />
                            <s.TextDescription>Jorge</s.TextDescription>
                            <s.TextDescription>Backup Dev</s.TextDescription>
                        </MemberContainer>
                        <MemberContainer isMobile={isMobile} onClick={() => openInNewTab('https://www.linkedin.com/in/antonia-puga-salman-20582bbb/')}>
                            <Member src={ANTO} />
                            <s.TextDescription>Antonia</s.TextDescription>
                            <s.TextDescription>Video Content</s.TextDescription>
                        </MemberContainer>
                    </MembersRow>
                </s.Container>
            </s.Container>
        </s.FullScreenDiv>
    );
});

export default Team;