import { forwardRef } from 'react';
import styled from 'styled-components';
import { FROG, TREE, TRUNK } from '../constants/constants';
import { useIsMobile } from '../hooks/windowSize';
import * as s from '../styles/globalStyles';

const DescriptionContainer = styled(s.Container)`
    flex-direction: column;
    align-items: center;
    max-width: 60%;
    margin: 0 20px;
    margin-bottom: 24px;
    flex-grow: 1;
`;

const GoalContainer = styled(s.Container)`
    flex-direction: ${({ fd }) => (fd ? fd : "column")};
    align-items: center;
    justify-content: space-between;
    width: 100%;
`;

const ShinyGreenText = styled(s.TextDescription)`
    color: var(--shiny-green);
`;

const ShinyYellowText = styled(s.TextDescription)`
    color: var(--shiny-yellow);
`;

const ShinyRedText = styled(s.TextDescription)`
    color: var(--shiny-red);
`;

const ImgContainer = styled.div`
    flex-grow: 1;
    display: flex;
    max-width: 100px;
`;

const Img = styled.img`
    max-width: 100%;
`;

const GrowthMap = forwardRef((props, ref) => {
    const isMobile = useIsMobile();

    const growthMapContainerStyle = {
        backgroundColor: 'var(--medium-opacity)',
        height: 'auto',
        paddingBottom: 50
    };

    const growthMapStyle = {
        margin: '0 auto',
        width: '70vw',
        backgroundColor: 'var(--strong-opacity)',
        flexDirection: 'row'
    };

    return (
        <s.FullScreenDiv style={growthMapContainerStyle} ref={ref}>
            <s.Container style={growthMapStyle}>
                <s.Container fg={1} ai='center' style={{ marginBottom: 60 }}>
                    <s.Container ai='center' style={{ margin: '30px 0' }}>
                        <s.MainTitle style={{ textAlign: 'center' }}>Growth Map</s.MainTitle>
                    </s.Container>
                    <s.Container fd={ isMobile ? 'column' : 'row'} jc='space-evenly' ai='center'>
                        <ImgContainer>
                            <Img src={FROG} style={{ transform: 'scaleX(-1)' }} />
                        </ImgContainer>
                        <DescriptionContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Art collaboration alliance with DIEGONG
                                </s.TextDescription>
                                <ShinyGreenText>
                                    (COMPLETED)
                                </ShinyGreenText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Design Brainstorming, Base Figure, Variation Design
                                </s.TextDescription>
                                <ShinyGreenText>
                                    (COMPLETED)
                                </ShinyGreenText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Probalistic Designs, Variability Development Collab
                                </s.TextDescription>
                                <ShinyGreenText>
                                    (COMPLETED)
                                </ShinyGreenText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    "KEULE 3D" Environment Testing UR5
                                </s.TextDescription>
                                <ShinyGreenText>
                                    (COMPLETED)
                                </ShinyGreenText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Front-End Design and Dev - Minting & Project Website
                                </s.TextDescription>
                                <ShinyGreenText>
                                    (COMPLETED)
                                </ShinyGreenText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Smart Contract Development
                                </s.TextDescription>
                                <ShinyGreenText>
                                    (COMPLETED)
                                </ShinyGreenText>
                            </GoalContainer>
                        </DescriptionContainer>
                        {!isMobile && <ImgContainer style={{ justifyContent: 'flex-end' }}>
                            <Img src={FROG} />
                        </ImgContainer>}
                    </s.Container>
                    <s.Container fd={ isMobile ? 'column' : 'row'} jc='space-evenly' ai='center'>
                        <ImgContainer>
                            <Img src={TRUNK} style={{ transform: 'scaleX(-1)' }} />
                        </ImgContainer>
                        <DescriptionContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Community Building - Greenlisting
                                </s.TextDescription>
                                <ShinyYellowText>
                                    (ON GOING)
                                </ShinyYellowText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Carbon Capturing Mini Game "WORM 3" 
                                </s.TextDescription>
                                <ShinyYellowText>
                                    (ON GOING)
                                </ShinyYellowText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    "KEULE 3D" Alpha Environment
                                </s.TextDescription>
                                <ShinyYellowText>
                                    (ON GOING)
                                </ShinyYellowText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    "KEULE 3D" Teaser
                                </s.TextDescription>
                                <ShinyYellowText>
                                    (ON GOING)
                                </ShinyYellowText>
                            </GoalContainer>
                        </DescriptionContainer>
                        {!isMobile && <ImgContainer>
                            <Img src={TRUNK} />
                        </ImgContainer>}
                    </s.Container>
                    <s.Container fd={ isMobile ? 'column' : 'row'} jc='space-evenly' ai='center'>
                        <ImgContainer>
                            <Img src={TREE} style={{ transform: 'scaleX(-1)' }} />
                        </ImgContainer>
                        <DescriptionContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    1st Minting - 1s Greenlist
                                </s.TextDescription>
                                <ShinyRedText>
                                    (NOV 2022)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    "KEULE 3D" Teaser Release
                                </s.TextDescription>
                                <ShinyRedText>
                                    (DEC 2022)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    1st Airdrop
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    2nd Minting - 2nd Greenlist
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    2nd Airdrop
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    3rd Minting - 3rd Greenlist
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    3rd Airdrop
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    OPEN MINTING, 4rd Airdrop
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    Entrees Staking Platform - $ROOTS Farming
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                            <GoalContainer fd={ isMobile ? 'column' : 'row'}>
                                <s.TextDescription>
                                    "KEULE 3D" Full Environment Release
                                </s.TextDescription>
                                <ShinyRedText>
                                    (TBA)
                                </ShinyRedText>
                            </GoalContainer>
                        </DescriptionContainer>
                        {!isMobile && <ImgContainer>
                            <Img src={TREE} />
                        </ImgContainer>}
                    </s.Container>
                </s.Container>
            </s.Container>
        </s.FullScreenDiv>
    );
});

export default GrowthMap;